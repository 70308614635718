<template>
  <div
    class="d-flex login justify-content-center align-items-center vh-100"
    style="
      border-radius: 4px;
      background-color: #e4ecfe;
      background-image: url('../../assets/bg-pattern.png'),
        url('../assets/spiral-pattern.png');
      background-repeat: repeat, repeat;
      background-position: center, center;
      background-size: cover;
    "
  >
    <div class="container" @submit.prevent="tryToLogIn">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
          <div class="login-container p-0">
            <div
              class="logo pt-4 pb-4 text-center"
              style="border-top-left-radius: 4px; border-top-right-radius: 4px"
            >
              <img style="height: 32px" src="../../assets/logo.png" alt="" />
            </div>
            <div class="px-4 py-2">
              <div class="text-center m-2">
                <h5 class="text-dark mt-4 -80 text-center title text-muted">
                  Sign In
                </h5>
              </div>
              <div
                v-if="isAuthError"
                class="alert alert-danger alert-dismissible fade show fw-normal"
                role="alert"
              >
                {{ authError }}
                <button
                  type="button"
                  class="btn-close btn-sm"
                  @click="isAuthError = false"
                  aria-label="Close"
                ></button>
              </div>

              <form class="login-form mt-0">
                <div class="form-group fw-bold">
                  <label for="username">Username </label>
                  <input
                    class="form-control"
                    type="text"
                    id="username"
                    v-model="username"
                    name="username"
                    placeholder="Enter Username"
                    required
                  />
                </div>

                <div class="form-group input-group-2">
                  <div class="d-flex justify-content-between">
                    <label class="fw-bold">Password</label>
                    <router-link
                      to="/forgot-password"
                      class="text-muted fw-normal"
                      style="font-size: 0.8rem"
                    >
                      Forgot your password?</router-link
                    >
                  </div>
                  <div class="input-group mb-3">
                    <input
                      v-model="password"
                      class="form-control"
                      :type="hidePassword ? 'password' : 'text'"
                      placeholder="Enter Password"
                    />
                    <div
                      class="input-group-text text-white"
                      @click="togglePassword"
                      style="cursor: pointer; background-color: #727cf5"
                    >
                      <i
                        :class="
                          hidePassword ? 'ri-eye-line' : 'ri-eye-off-line'
                        "
                        style="font-size: 18px"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="text-center mb-3">
                  <button type="submit" class="btn btn-primary buttonPrimary">
                    Log In
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div>
            <p
              class="text-center text-muted mt-4 fw-bold"
              style="font-size: 14px"
            >
              v2.0.0
            </p>
          </div>
          <div class="loginFooter text-muted text-center fw-normal">
            ©{{ new Date().getFullYear() }} - All rights reserved by ProenX Pte
            Ltd.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useAuthStore } from "../../store/auth";
import { useRouter } from "vue-router";

const router = useRouter();
const authStore = useAuthStore();
const isAuthError = ref(false);
const authError = ref(null);
const tryingToLogIn = ref(false);
const username = ref("");
const password = ref("");
const hidePassword = ref(true);

onMounted(() => {
  document.title = "Login | RDEP";
});

const togglePassword = () => {
  hidePassword.value = !hidePassword.value;
};

onMounted(() => {
  document.title = "Login | RDEP";

  // Add global keydown event listener
  document.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  // Remove global keydown event listener
  document.removeEventListener("keydown", handleKeyDown);
});

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    tryToLogIn();
  }
};

const tryToLogIn = () => {
  tryingToLogIn.value = false;
  authError.value = null;
  return authStore
    .LogIn({ username: username.value, password: password.value })
    .then((response) => {
      tryingToLogIn.value = true;
      // isAuthError.value = true;

      if (response.firstTimeLogin) {
        router.push("/reset-password");
      } else {
        // router.push(response.landingPage);
        if (response.landingPage === "rdep-receipt") {
          router.push("/rdep-receipt/dashboard");
        } else {
          router.push(response.landingPage);
        }
      }
    })
    .catch((error) => {
      tryingToLogIn.value = false;
      authError.value = error.message;
      isAuthError.value = true;
    });
};
</script>

<style>
.buttonPrimary {
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5) !important;
  background-color: #6366f1 !important;
  border: #6366f1 !important;
}
.logo {
  background-color: #727cf5;
}

.login-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container h2 {
  color: #333;
}

.login-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  margin-top: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 4px 4px;
}

.form-group label {
  font-size: 12px;
  margin-bottom: 5px;
  color: #6c757d;
}

.form-group input {
  color: #6c757d !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.login-btn {
  background-color: #727cf5;
  color: #fff;
  padding: 6px 10px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 14px;
}

h6 {
  color: #6c757d;
  font-weight: 700;
}

.forget-password {
  font-size: 12px;
  color: #6c757d;
  text-decoration: none;
  text-align: right;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da; /* Set border color to desired color */
}

.loginFooter {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px !important;
  text-align: center;
}
</style>
