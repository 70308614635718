<template>
  <div id="view">
    <Topbar :menuList="menuItems" />
    <div class="container-fluid mt-1">
      <router-view></router-view>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script setup>
import Topbar from "../../components/Navbar/TopbarView.vue";
import Footer from "../../components/Footer/FooterView.vue";
// import axios from "axios";
import { ref, onMounted, watch } from "vue";
import { usePermissionStore } from "../../store/helper/menuPaths.js";
import { useMenuStore } from "../../store/helper/posMenuStore";
import { useRoute } from "vue-router";

const menuStore = useMenuStore();
const route = useRoute();
const permissionStore = usePermissionStore();
const menuItems = ref([]);
const isPosMenuRoutes = ref(false);

watch(
  () => route.fullPath,
  (newFullPath) => {
    isPosMenuRoutes.value = newFullPath.includes("/rdep-receipt");
  },
  { immediate: true }
);

const fetchMenuData = async () => {
  try {
    const data = await menuStore.fetchMenuData();
    menuItems.value = data;
    permissionStore.updateAllowedPaths(data);
  } catch (error) {
    console.error("Error fetching menu data:", error);
  }
};

onMounted(() => {
  if (isPosMenuRoutes.value !== true) {
    fetchMenuData();
  }
});
</script>

<style>
@import url("../../style/style.css");
</style>
