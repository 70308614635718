<template>
  <div class="main_app">
    <router-view />
  </div>
</template>

<script setup>
import { provide } from "vue";
import { useMenuStore } from "./store/helper/posMenuStore"; // Make sure to create this file

const menuStore = useMenuStore();

// Provide the menuStore to all child components
provide("menuStore", menuStore);

// menuStore.fetchMenuData();
</script>

<style>
@import url("./style/style.css");
</style>
