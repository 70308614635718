import { ref, readonly } from "vue";
import axios from "axios";
import { menuItems as routerMenuItems } from "../../router/router";

const menuItems = ref([]);
const isMenuFetched = ref(false);
const isLoading = ref(false);

let menuDataPromise = null;

export function useMenuStore() {
  const fetchMenuData = async () => {
    if (isMenuFetched.value) return menuItems.value;
    if (menuDataPromise) return menuDataPromise;

    isLoading.value = true;
    // console.log("Fetching menu data...");

    menuDataPromise = axios
      .get("/auth-service/ui/component/list/pos-console")
      .then((response) => {
        // console.log("Received menu data:", response.data);
        const data = response.data;
        if (data.status === "success") {
          // console.log("Transforming menu items...");
          menuItems.value = transformMenuItems(data.menus);
          isMenuFetched.value = true;
          // console.log("Transformed menu items:", menuItems.value);
          return menuItems.value;
        } else {
          throw new Error("Failed to fetch menu data");
        }
      })
      .catch((error) => {
        // console.error("Error fetching menu data:", error);
        throw error;
      })
      .finally(() => {
        isLoading.value = false;
        menuDataPromise = null;
      });

    return menuDataPromise;
  };

  const transformMenuItems = (menus) => {
    return menus.map((menu) => {
      const existingMenuItem = routerMenuItems.find(
        (item) => item.label === menu.name
      );
      return {
        label: menu.name,
        icon: existingMenuItem?.icon || "ri-shopping-bag-line ri-lg",
        children: menu.subMenus?.map((subMenu) => ({
          label: subMenu.name,
          path: `/${menu.name.replace(/\s/g, "").toLowerCase()}/${subMenu.name
            .replace(/\s/g, "")
            .toLowerCase()}`,
          isComponent: subMenu.isComponent,
          icon:
            routerMenuItems.find((item) => item.label === subMenu.name)?.icon ||
            "ri-shopping-bag-line ri-lg",
        })),
        path: menu.isComponent
          ? `/${menu.name.replace(/\s/g, "").toLowerCase()}`
          : undefined,
      };
    });
  };

  return {
    menuItems: readonly(menuItems),
    isMenuFetched: readonly(isMenuFetched),
    isLoading: readonly(isLoading),
    fetchMenuData,
  };
}
